import { createStore } from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import auth from "./modules/auth";
import personal from "./modules/personal/personal";
import student from "./modules/personal/student";
import customer from "./modules/personal/customer";
import test from "./modules/test";
import application from './modules/application';
import dadata from './modules/dadata';
import about from './modules/about';

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    auth,
    personal,
    student,
    customer,
    test,
    application,
    dadata,
    about
  }
});
