import { createApp } from "vue";
import App from "./App.vue";

import axios from '@/axios';
import store from "./store";
import router from "./router";
import "@/sass/app.min.sass";

const app = createApp(App);
app.use(store);
app.use(router);
app.mount("#app");

app.component('ButtonBack', require('@/components/button-back').default)

const token = localStorage.getItem("user-token");

if (token) {
    axios.defaults.headers.common['Authorization'] = token
}

window.makeId = () => {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < 8; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))

    return text
}
