import axios from '@/axios';

const state = {
    usersTypes: [],
    selectUserType: null,
    сategories: [],
    applications: [],
    responseArr: [],
    errors: {},
    errorStudents: {},
    dataForForms: [
        {
            types: [],
            courses: [],
            subcategories: [],
            times: [],
            selectedCategory: null,
            selectedSubCategory: null,
            selectedType: null,
            selectedCourse: null,
            selectedTimeId: null,
            selectedTime: null,
        }
    ],
}

const getters = {
    getUserRegisterFields: state => {
        return state.usersTypes.find(type => type.user_type.code === state.selectUserType).fields
    },

    getUserType: state => {
        return state.usersTypes.find(type => type.user_type.code === state.selectUserType)
    },
    getCategories: state => {
        return state.сategories
    },

    getApplications: state => {
        return state.applications
    },

    getFormIndexByFormId: state => formId => {
        return state.applications.findIndex(application => application.key === formId)
    },

    getErrorByCodeAndFormIndex: state => (formIndex, code) =>  {
        const errorCode = `courses.${formIndex}.${code}`
        if( typeof state.errors[errorCode] === 'undefined')
            return null

        return state.errors[errorCode]
    },

    getFullPrice: state => {
        let fullPrice = 0

        state.applications.forEach((application) => {
            if (application.course.price)
                fullPrice += application.course.price
        })

        return fullPrice
    }
}

const mutations = {
    APPLICATION_SET_USER_TYPES(state, types) {
        state.usersTypes = types
    },

    APPLICATION_SET_USER_TYPE(state, type) {
        state.selectUserType = type
    },

    APPLICATION_SET_CATEGORIES(state, categories) {
        state.сategories = categories
    },

    APPLICATION_CREATE_COURSE(state) {
        state.applications.push({
            key: window.makeId(),
            course: {
                selectedCourseId: null,
                startDate: null,
                endDate: null,
                otherDates: false,
                priceId: null,
                price: null,
            },
            students: [],
            fields: []
        })
        state.dataForForms.push({
            types: [],
            courses: [],
            subcategories: [],
            times: [],
            selectedCategory: null,
            selectedSubCategory: null,
            selectedType: null,
            selectedCourse: null,
            selectedTimeId: null,
            selectedTime: null,
        })
    },

    APPLICATION_INIT_COURSE(state, key) {
        state.applications.push({
            key: key,
            course: {
                selectedCourseId: null,
                startDate: null,
                endDate: null,
                otherDates: false,
                priceId: null,
                price: null,
            },
            students: [],
            fields: []
        })
    },

    APPLICATION_FILL_COURSE(state, data) {
        state.applications[data.index].course = data.course
    },

    APPLICATION_SET_COURSE_ID(state, payload) {
        const form = state.applications.find(el => el.key === payload.formId)
        form.course.selectedCourseId = payload.courseId
    },

    APPLICATION_ADD_START_DATE(state, payload) {
        const form = state.applications.find(el => el.key === payload.formId)
        form.course.startDate = payload.startDate
    },

    APPLICATION_ADD_END_DATE(state, payload) {
        const form = state.applications.find(el => el.key === payload.formId)
        form.course.endDate = payload.endDate
    },

    APPLICATION_ADD_OTHER_DATES(state, payload) {
        const form = state.applications.find(el => el.key === payload.formId)
        form.course.otherDates = payload.otherDates
    },

    APPLICATION_ADD_COURSE_FIELDS(state, payload) {
        const form = state.applications.find(el => el.key === payload.formId)
        form.fields = payload.fields
    },

    APPLICATION_CREATE_STUDENT(state, payload) {
        const form = state.applications.find(el => el.key === payload.formId)
        form.students.push(payload.student)
    },

    APPLICATION_SET_STUDENT(state, payload) {
        const form = state.applications.find(el => el.key === payload.formId)
        let student = form.students.find(el => el.key === payload.studentId)

        student['fields'] = payload.student
    },

    APPLICATION_REMOVE_STUDENT(state, payload) {
        const form = state.applications.find(el => el.key === payload.formId)
        form.students = form.students.filter(el => el.key !== payload.studentId)
    },

    APPLICATION_REMOVE_COURSE(state, payload) {
		state.applications = state.applications.filter(el => el.key !== payload)
	},

    APPLICATION_SET_ERRORS(state, payload) {
        state.errors = payload
    },

    APPLICATION_SET_COURSE_PRICE(state, payload) {
        const form = state.applications.find(el => el.key === payload.formId)
        form.course.priceId = payload.priceId
    },

    APPLICATION_SET_ERRORS_STUDENTS(state, payload) {
        state.errorStudents = payload
    },
    APPLICATION_SET_PRICE(state, payload) {
        const form = state.applications.find(el => el.key === payload.formId)
        form.course.price = payload.price
    },
    APPLICATION_SET_DATA_FORM_TYPES(state, payload) {
        state.dataForForms[payload.id].types = payload.data
    },
    APPLICATION_SET_DATA_FORM_COURSES(state, payload) {
        state.dataForForms[payload.id].courses = payload.data
    },
    APPLICATION_SET_DATA_FORM_SUBCATEGORIES(state, payload) {
        state.dataForForms[payload.id].subcategories = payload.data
    },
    APPLICATION_SET_DATA_FORM_TIMES(state, payload) {
        state.dataForForms[payload.id].times = payload.data
    },
    APPLICATION_SET_DATA_FORM(state, payload) {
        state.dataForForms[payload.id][payload.key] = payload.data
    },
}

const actions = {
    APPLICATION_GET_FIELDS({ commit }) {
        commit("LAUNCH_PRELOADER")

        return new Promise((resolve, reject) => {
            axios
                .get('/auth/register/show')
                .then(response => {
                    commit("APPLICATION_SET_USER_TYPES", response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit("STOP_PRELOADER")
                })
        })
    },

    APPLICATION_SEND_FORM({ commit }, request) {
        commit("LAUNCH_PRELOADER")

        return new Promise((resolve, reject) => {
            axios
                .post('/auth/register/store', request)
                .then(response => {
                    const token = response.data.token;
                    const token_type = response.data.token_type;

                    localStorage.setItem(
                        "user-token",
                        token_type + " " + token
                    );
                    axios.defaults.headers.common["Authorization"] =
                        token_type + " " + token;

                    commit("AUTH_SET_TOKEN", token);
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit("STOP_PRELOADER")
                })
        })
    },

    APPLICATION_GET_CATEGORIES({ commit }) {
        commit("LAUNCH_PRELOADER")

        return new Promise((resolve, reject) => {
            axios
                .get('/customer/catalog/categories')
                .then(response => {
                    commit("APPLICATION_SET_CATEGORIES", response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit("STOP_PRELOADER")
                })
        })
    },

    APPLICATION_GET_TYPES({ commit }, data) {
        commit("LAUNCH_PRELOADER")

        return new Promise((resolve, reject) => {
            axios
                .get(`/customer/catalog/categories/types?category_id=${data.id}`)
                .then(response => {
                    commit('APPLICATION_SET_DATA_FORM_TYPES', {
                        id: data.formId,
                        data: response.data
                    })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit("STOP_PRELOADER")
                })
        })
    },

    APPLICATION_GET_COURSES({ commit }, data) {
        commit("LAUNCH_PRELOADER")

        return new Promise((resolve, reject) => {
            axios
                .get(`/customer/catalog/categories/types/show?type_id=${data.id}`)
                .then(response => {
                    commit('APPLICATION_SET_DATA_FORM_COURSES', {
                        id: data.formId,
                        data: response.data.courses
                    })
                    commit('APPLICATION_SET_DATA_FORM_SUBCATEGORIES', {
                        id: data.formId,
                        data: response.data.subcategories
                    })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit("STOP_PRELOADER")
                })
        })
    },

    APPLICATION_GET_COURSE_TIME({ commit }, request) {
        commit("LAUNCH_PRELOADER")

        return new Promise((resolve, reject) => {
            axios
                .post('/customer/catalog/categories/courses/show', { courses: [request.id] })
                .then(response => {
                    const times = []
                    response.data[0].course.prices.forEach(item =>
                        times.push({name: item.hours + ' академ.часов', id: item.id, hours: item.hours})
                    )
                    commit('APPLICATION_SET_DATA_FORM_TIMES', {
                        id: request.formId,
                        data: times
                    })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit("STOP_PRELOADER")
                })
        })
    },

    APPLICATION_GET_USER_FIELDS({ commit }, payload) {
        commit("LAUNCH_PRELOADER")

        return new Promise((resolve, reject) => {
            axios
                .get('/customer/orders/students/create', {
                    params: payload.request
                })
                .then(response => {
                    commit('APPLICATION_ADD_COURSE_FIELDS', {
                        "formId": payload.form,
                        "fields": response.data
                    })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit("STOP_PRELOADER")
                })
        })
    },

    APPLICATION_GET_EMPLOYEES({ commit }) {
        commit("LAUNCH_PRELOADER")

        return new Promise((resolve, reject) => {
            axios
                .get('/customer/students')
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit("STOP_PRELOADER")
                })
        })
    },

    APPLICATION_SEND_COURSES({ commit }, payload) {
        commit('LAUNCH_PRELOADER')

        return new Promise((resolve, reject) => {
            axios
                .post('/customer/orders/store', payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit('STOP_PRELOADER')
                })
        })
    },

    APPLICATION_GET_COURSE_PRICE({ commit }, payload) {
        commit("LAUNCH_PRELOADER")

        return new Promise((resolve, reject) => {
            axios
                .post('/customer/catalog/categories/courses/show',  payload.courseId)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit("STOP_PRELOADER")
                })
        })
    },
    APPLICATION_GET_PRICE({ commit }, payload) {
        commit("LAUNCH_PRELOADER")
        return new Promise((resolve, reject) => {
            axios
                .post('/discount-by-count',  payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit("STOP_PRELOADER")
                })
        })
    },
    APPLICATION_SET_LOCAL_STORAGE({ commit }, uid) {
        commit("LAUNCH_PRELOADER")
        return new Promise((resolve, reject) => {
            axios
                .post('/redirect/lk/pull',  { uid: uid })
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit("STOP_PRELOADER")
                })
        })
    },
}

export default { state, getters, mutations, actions }
