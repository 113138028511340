import axios from "@/axios";

const state = {
    currentTest: null,
    isResolved: false
};

const mutations = {
    TEST_BASE_STATE(state, payload) {
        state[payload.key] = payload.data;
    },
    TEST_IS_RESOLVE(state, value) {
        state.isResolved = value;
    }
};

const actions = {
    TEST_FETCH_QUESTIONS({ commit }, data) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/student/courses/test?test_id=${data.testId}&is_intermediate=${data.isIntermediate}`
                )
                .then(response => {
                    commit("TEST_BASE_STATE", {
                        key: "currentTest",
                        data: response.data
                    });
                    commit("TEST_IS_RESOLVE", response.data.has_resolved);
                    commit("STOP_PRELOADER");
                    resolve(response);
                })
                .catch(error => {
                    commit("STOP_PRELOADER");
                    reject(error);
                });
        });
    },
    TEST_CHECK_ANSWERS({ commit }, result) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
            axios
                .post("/student/courses/test/result", result)
                .then(response => {
                    commit("TEST_BASE_STATE", {
                        key: "currentTest",
                        data: response.data
                    });
                    commit("TEST_IS_RESOLVE", response.data.has_resolved);
                    commit("STOP_PRELOADER");
                    resolve(response);
                })
                .catch(error => {
                    commit("STOP_PRELOADER");
                    reject(error);
                });
        });
    }
};

export default {
    state,
    actions,
    mutations
};
