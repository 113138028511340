<template>
    <SvgContainer />
    <router-view />
    <Preloader v-show="isPreloader" />
</template>

<script>
import axios from "@/axios";

import SvgContainer from "./components/svg-container";
import Preloader from "./components/preloader";

export default {
    components: {
        SvgContainer,
        Preloader
    },

    computed: {
        isPreloader() {
            return this.$store.state.isPreloader;
        }
    },

    async created() {
        axios.interceptors.response.use(undefined, error => {
            return new Promise(() => {
                const response = error.response;

                switch (response.status) {
                    // case 404:
                    //     this.$store.commit("ROUTE_REDIRECT", "404");
                    //     break;
                    case 401:
                        this.$store.commit("ROUTE_REDIRECT", "main");
                        break;
                }

                throw error;
            });
        });
    }
};
</script>
