import axios from "@/axios";

const state = {
    page: null,
};

const mutations = {
    ABOUT_SET_PAGE(state, page) {
        state.page = page;
    },
};

const actions = {
    FETCH_STATIC_PAGE({ commit }, code) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
            axios
                .get('/static-pages/' + code)
                .then(response => {
                    commit("ABOUT_SET_PAGE", response.data);
                    commit("STOP_PRELOADER");
                    resolve(response);
                })
                .catch(error => {
                    commit("STOP_PRELOADER");
                    reject(error);
                });
        });
    }
};

export default {
    state,
    actions,
    mutations
};
