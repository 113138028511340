import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production'
    ? 'https://mait-nauka.ru/api/v1'
    : 'http://dev.mait.ru/api/v1';

const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

export default axios.create({
  baseURL,
})
