import axios from '@/axios';

const state = {
    mainArticles: null,
    article: null,
    listArticles: null,
    statistics: null,
    courses: null,
    course: null
};

const mutations = {
    STUDENT_BASE_STATE(state, payload) {
        state[payload.key] = payload.data;
    },
};

const actions = {
    PERSONAL_GET_ARTICLES({ commit }) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
        axios
            .get("/student/articles")
            .then(response => {
            commit("STUDENT_BASE_STATE", {
                key: "mainArticles",
                data: response.data,
            });
            commit("STOP_PRELOADER");
            resolve(response);
            })
            .catch(error => {
            commit("STOP_PRELOADER");
            reject(error);
            });
        });
    },

    PERSONAL_GET_ITEM_ARTICL({ commit }, articleId) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
        axios
            .get(`/student/articles/${articleId}`)
            .then(response => {
            commit("STUDENT_BASE_STATE", {
                key: "article",
                data: response.data.main,
            });
            commit("STUDENT_BASE_STATE", {
                key: "listArticles",
                data: response.data.list,
            });
            commit("STOP_PRELOADER");
            resolve(response);
            })
            .catch(error => {
            commit("STOP_PRELOADER");
            reject(error);
            });
        });
    },

    PERSONAL_GET_STATISTICS({ commit }) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
        axios
            .get("/student/statistic")
            .then(response => {
            commit("STUDENT_BASE_STATE", {
                key: "statistics",
                data: response.data,
            });
            commit("STOP_PRELOADER");
            resolve(response);
            })
            .catch(error => {
            commit("STOP_PRELOADER");
            reject(error);
            });
        });
    },

    PERSONAL_GET_COURSES({ commit }) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
        axios
            .get("/student/courses")
            .then(response => {
            commit("STUDENT_BASE_STATE", {
                key: "courses",
                data: response.data,
            });
            commit("STOP_PRELOADER");
            resolve(response);
            })
            .catch(error => {
            commit("STOP_PRELOADER");
            reject(error);
            });
        });
    },
    PERSONAL_GET_ITEM_COURSE({ commit }, slug) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
        axios
            .get(`/student/courses/${slug}`)
            .then(response => {
            commit("STUDENT_BASE_STATE", {
                key: "course",
                data: response.data,
            });

            commit("STOP_PRELOADER");
            resolve(response);
            })
            .catch(error => {
            commit("STOP_PRELOADER");
            reject(error);
            });
        });
    },
};

export default {
    state,
    actions,
    mutations,
};
