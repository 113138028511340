import axios from "@/axios";

const state = {
    suggestions: [],
    selectedSuggestion: null,
};

const mutations = {
    DADATA_SET_SUGGESTIONS(state, value) {
        state.suggestions = value;
    },
    DADATA_GET_SELECTED_SUGGESTION(state, value) {
        state.selectedSuggestion = value;
    }
};

const actions = {
    DADATA_GET_INFO({ commit }, query) {
        const headers = { headers : { Authorization: "Token 49f6c010a1ee38ba41bee6f0db2c4f55995f21d3" }}

        return new Promise((resolve, reject) => {
            axios
                .post("https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party", query, headers)
                .then(response => {
                    commit("DADATA_SET_SUGGESTIONS", response.data.suggestions);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export default {
    state,
    actions,
    mutations
};
