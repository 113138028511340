import route from "@/router";

const mutations = {
    LAUNCH_PRELOADER(state) {
        state.isPreloader = true;
    },

    STOP_PRELOADER(state) {
        state.isPreloader = false;
    },

    SET_BASE_STATE(state, payload) {
        state[payload.key] = payload.data;
    },

    ROUTE_REDIRECT(state, routeName) {
        route.push({ name: routeName });
    }
};

export default mutations;
