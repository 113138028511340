import axios from "@/axios";

const state = {
    token: localStorage.getItem("user-token") || ""
};

const getters = {
    isAuthenticated: state => !!state.token
};

const mutations = {
    AUTH_SET_TOKEN(state, token) {
        state.token = token;
    },

    LOGOUT_CLEAR: state => {
        state.token = "";
        localStorage.removeItem("user-token");
        delete axios.defaults.headers.common.Authorization;
    }
};

const actions = {
    AUTH_LOGIN_REQUEST({ commit }, request) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
            axios
                .post("/auth/login", request)
                .then(response => {
                    const token = response.data.token;
                    const token_type = response.data.token_type;

                    localStorage.setItem(
                        "user-token",
                        token_type + " " + token
                    );
                    axios.defaults.headers.common["Authorization"] =
                        token_type + " " + token;

                    commit("AUTH_SET_TOKEN", token);
                    commit("STOP_PRELOADER");
                    resolve(response);
                })
                .catch(error => {
                    commit("LOGOUT_CLEAR");
                    commit("STOP_PRELOADER");
                    reject(error);
                });
        });
    },

    AUTH_RESTORE_PASSWORD({ commit }, email) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
            axios
                .post("/auth/reset-password", email)
                .then(response => {
                    commit("STOP_PRELOADER");
                    resolve(response);
                })
                .catch(error => {
                    commit("STOP_PRELOADER");
                    reject(error);
                });
        });
    },
    AUTH_LOGOUT({ commit }) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
            axios
                .delete("/auth/logout")
                .then(response => {
                    commit("LOGOUT_CLEAR");
                    commit("PERSONAL_GET_USER_DATA", null);
                    commit("PERSONAL_GET_FIELDS", null);
                    commit("PERSONAL_GET_ERROR", null);
                    commit("STOP_PRELOADER");
                    resolve(response);
                })
                .catch(error => {
                    commit("STOP_PRELOADER");
                    commit("LOGOUT_CLEAR");
                    reject(error);
                });
        });
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
