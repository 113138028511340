import axios from "@/axios";

const state = {
    orders: null,
    courses: null,
    course: null,
    students: null,
    progress: null,
    employees: null,
    isSaveChange: false,
    studentsTableNames: null,
};

const mutations = {
    CUSTOMER_BASE_STATE(state, payload) {
        state[payload.key] = payload.data;
    },
    CUSTOMER_SET_STUDENT(state, payload) {
        const index = state.students.findIndex(item => item.id === payload.id)
        state.students[index] = payload
    },
    CUSTOMER_SET_EMPLOYEES(state, payload) {
        const index = state.employees.findIndex(item => item.id === payload.id)
        state.employees[index] = payload
    },
    CUSTOMER_DELETE_EMPLOYE(state, id) {
        const index = state.employees.findIndex(item => item.id === id)
        state.employees.splice(index, 1)
    },
    CUSTOMER_SET_ORDER(state, order) {
        const index = state.orders.findIndex(item => item.id === order.id)
        state.orders[index] = order
    },
    CUSTOMER_SET_DATA_COURSE(state, dates) {
        state.course.dates = dates
    },
    CUSTOMER_SAVE_CHANGE(state, value) {
        state.isSaveChange = value
    },
};

const actions = {
    CUSTOMER_GET_ORDERS({commit}) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
            axios
                .get("/customer/orders")
                .then(response => {
                    commit("CUSTOMER_BASE_STATE", {
                        key: "orders",
                        data: response.data
                    });
                    commit("STOP_PRELOADER");
                    resolve(response);
                })
                .catch(error => {
                    commit("STOP_PRELOADER");
                    reject(error);
                });
        });
    },
    CUSTOMER_GET_COURSES({commit}) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
            axios
                .get("/customer/courses")
                .then(response => {
                    commit("CUSTOMER_BASE_STATE", {
                        key: "courses",
                        data: response.data
                    });
                    commit("STOP_PRELOADER");
                    resolve(response);
                })
                .catch(error => {
                    commit("STOP_PRELOADER");
                    reject(error);
                });
        });
    },
    CUSTOMER_GET_ITEM_COURSE({commit}, slug) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
            axios
                .get(`/customer/courses/${slug}`)
                .then(response => {
                    commit("CUSTOMER_BASE_STATE", {
                        key: "course",
                        data: response.data.course
                    });
                    commit("CUSTOMER_BASE_STATE", {
                        key: "students",
                        data: response.data.students
                    });
                    commit("CUSTOMER_BASE_STATE", {
                        key: "progress",
                        data: response.data.progress
                    });
                    commit("CUSTOMER_BASE_STATE", {
                        key: "studentsTableNames",
                        data: response.data.course_table_names
                    });
                    commit("STOP_PRELOADER");
                    resolve(response);
                })
                .catch(error => {
                    commit("STOP_PRELOADER");
                    reject(error);
                });
        });
    },
    CUSTOMER_GET_EMPLOYEES({commit}) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
            axios
                .get("/customer/students")
                .then(response => {
                    commit("CUSTOMER_BASE_STATE", {
                        key: "employees",
                        data: response.data
                    });
                    commit("STOP_PRELOADER");
                    resolve(response);
                })
                .catch(error => {
                    commit("STOP_PRELOADER");
                    reject(error);
                });
        });
    },
    CUSTOMER_UPDATE_DATA_STUDENTS({commit}, data) {
        return new Promise((resolve, reject) => {
            axios
                .post("/customer/students/update/field", data)
                .then(response => {
                    commit("CUSTOMER_SAVE_CHANGE", true);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    CUSTOMER_DELETE_EMPLOYEES({commit}, employeesId) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
            axios
                .delete("/customer/students/destroy", { data: {id: employeesId} })
                .then(response => {
                    commit("CUSTOMER_DELETE_EMPLOYE", employeesId);
                    commit("STOP_PRELOADER");
                    resolve(response);
                })
                .catch(error => {
                    commit("STOP_PRELOADER");
                    reject(error);
                });
        });
    },
    CUSTOMER_ADD_DAYS({commit}, data) {
        commit("LAUNCH_PRELOADER");
        return new Promise((resolve, reject) => {
            axios
                .patch("/customer/orders/extend", data)
                .then(response => {
                    resolve(response);
                    commit("STOP_PRELOADER");
                })
                .catch(error => {
                    reject(error);
                    commit("STOP_PRELOADER");
                });
        });
    },
    CUSTOMER_SAVE_DOCUMENT({commit}, data) {
        commit("LAUNCH_PRELOADER");
        return new Promise((resolve, reject) => {
            axios
                .get(`/customer/orders/docs/${data.type}?order_id=${data.orser_id}`, {
                    responseType: 'blob'
                })
                .then(response => {
                    resolve(response);
                    commit("STOP_PRELOADER");
                })
                .catch(error => {
                    reject(error);
                    commit("STOP_PRELOADER");
                });
        });
    },
    CUSTOMER_DELETE_ORDER({commit}, orderId) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
            axios
                .delete("/customer/orders/destroy", { data: { order_id: orderId }})
                .then(response => {
                    commit("CUSTOMER_BASE_STATE", {
                        key: "orders",
                        data: response.data
                    });
                    commit("STOP_PRELOADER");
                    resolve(response);
                })
                .catch(error => {
                    commit("STOP_PRELOADER");
                    reject(error);
                });
        });
    },

};

export default {
    state,
    actions,
    mutations
};
