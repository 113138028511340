import { createWebHistory, createRouter } from "vue-router";
import store from "@/store/index";

const isAuthenticated = (to, from, next) => {
    return store.getters.isAuthenticated ? next() : next("/login");
};

const isAuthenticatedApplication = (to, from, next) => {
    return store.getters.isAuthenticated ? next("/application/courses") : next();
};

const routes = [
    {
        path: "/login",
        component: () => import("../layouts/auth/Index.vue"),
        children: [
            {
                path: "",
                name: "main",
                component: () => import("../pages/auth/Index.vue")
            },
            {
                path: "student",
                name: "student",
                component: () => import("../pages/auth/Login.vue")
            },
            {
                path: "customer",
                name: "customer",
                component: () => import("../pages/auth/Login.vue")
            },
            {
                path: "restore",
                name: "restore",
                component: () => import("../pages/auth/Restore.vue")
            }
        ]
    },
    {
        path: "/",
        component: () => import("../layouts/personal/Index.vue"),
        beforeEnter: isAuthenticated,
        redirect: { name: "personal" },
        children: [
            {
                path: "personal",
                name: "personal",
                component: () => import("../pages/personal/Index.vue")
            },
            {
                path: "courses",
                name: "courses",
                component: () =>
                    import("../pages/personal/student/courses/Courses.vue")
            },
            {
                path: "courses/:slug",
                name: "courses-item",
                component: () =>
                    import("../pages/personal/student/courses/Courses-item.vue")
            },
            {
                path: "knowledge",
                name: "knowledge",
                component: () =>
                    import("../pages/personal/student/knowledge/Knowledge.vue")
            },
            {
                path: "knowledge/:slug",
                name: "knowledge-item",
                component: () =>
                    import(
                        "../pages/personal/student/knowledge/Knowledge-item.vue"
                    )
            },
            {
                path: "statistics",
                name: "statistics",
                component: () =>
                    import("../pages/personal/student/Statistics.vue")
            },
            {
                path: "performance",
                name: "performance",
                component: () =>
                    import(
                        "../pages/personal/customer/performance/Performance.vue"
                    )
            },
            {
                path: "performance/:slug/:order_id",
                name: "performance-item",
                component: () =>
                    import(
                        "../pages/personal/customer/performance/Performance-item.vue"
                    )
            },
            {
                path: "students",
                name: "students",
                component: () =>
                    import("../pages/personal/customer/employees/Employees.vue")
            },
            {
                path: "orders",
                name: "orders",
                component: () => import("../pages/personal/customer/Orders.vue")
            },
        ]
    },
    {
        path: "/confirm",
        component: () => import("../layouts/confirm/Index.vue"),
        abstract: true,
        redirect: "/confirm/email",
        children: [
            {
                path: "email",
                name: "courses-email",
                component: () =>
                    import("@/pages/application/courses/Confirmation.vue")
            },
        ]
    },
    {
        path: "/email-verify",
        component: () => import("../layouts/confirm/Index.vue"),
        // abstract: true,
        children: [
            {
                path: "/email-verify",
                name: "email-verify",
                component: () =>
                    import("@/pages/application/courses/ConfirmationSuccess.vue")
            },
        ]
    },
    {
        path: "/application",
        component: () => import("@/layouts/application/Index.vue"),
        children: [
            {
                path: "/application",
                component: () => import("@/pages/application/Index.vue"),
                redirect: { name: "application-registration-physical" },
                children: [
                    {
                        path: "registration",
                        redirect: { name: "application-registration-entity" },
                        beforeEnter: isAuthenticatedApplication,
                        component: () =>
                            import(
                                "@/pages/application/registration/Index.vue"
                            ),
                        children: [
                            {
                                path: "physical",
                                meta: { type: "physical" },
                                name: "application-registration-physical",
                                component: () =>
                                    import(
                                        "@/pages/application/registration/components/Fields.vue"
                                    )
                            },
                            {
                                path: "entity",
                                meta: { type: "entity" },
                                name: "application-registration-entity",
                                component: () =>
                                    import(
                                        "@/pages/application/registration/components/Fields.vue"
                                    )
                            },
                            {
                                path: 'individual',
                                meta: {type: 'individual'},
                                name: 'application-registration-individual',
                                component: () => import('@/pages/application/registration/components/Fields.vue'),
                            },
                        ]
                    },
                    {
                        path: "courses",
                        component: () =>
                            import("@/pages/application/courses/Index.vue")
                    }
                ]
            }
        ]
    },
    {
        path: "/about",
        component: () => import("@/layouts/about/Index.vue"),
        redirect: { name: "basic-information" },
        children: [
            {
                path: "/basic-information",
                name: "basic-information",
                component: () => import("../pages/about/Index.vue")
            },
            {
                path: "/information",
                name: "information",
                component: () => import("../pages/about/Index.vue")
            },
        ]
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0, behavior: "smooth" };
    }
});

export default router;
