import axios from '@/axios';

const actions = {
    SEND_QUESTIONS({ commit }, request) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
            axios
                .post('/form/question', request)
                .then(response => {
                    commit("STOP_PRELOADER");
                    resolve(response);
                })
                .catch(error => {
                    commit("STOP_PRELOADER");
                    reject(error);
                });
        });
    },
}

export default actions;
