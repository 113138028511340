<template>
    <button
        class="btn btn-back card__btn-back"
        :class="assignClass"
        @click.prevent="goBackPage"
    >
        <svg class="icon icon-arrow-left-bold ">
            <use xlink:href="#arrow-left-bold"></use>
        </svg>
        {{ nameButton }}
    </button>
</template>

<script>
import { computed } from "@vue/reactivity";
import { useRouter } from "vue-router";

export default {
    props: {
        assignClass: String,
        name: String
    },

    setup(props) {
        const router = useRouter();
        const goBackPage = () =>
            window.history.length > 1 ? router.back() : router.push("/");

        const nameButton = computed(() => (props.name ? props.name : "Назад"));

        return { goBackPage, nameButton };
    }
};
</script>
