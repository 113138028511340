import axios from '@/axios';

const state = {
    user: null,
    fields: null,
    errors: null
};

const mutations = {
    PERSONAL_GET_USER_DATA(state, user) {
        state.user= user;
    },
    PERSONAL_GET_FIELDS(state, fields) {
        state.fields= fields;
    },
    PERSONAL_GET_ERROR(state, errors) {
        state.errors= errors;
    },
};

const actions = {
    PERSONAL_FETCH_USER_DATA({ commit }) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
        axios
            .get("/user")
            .then(response => {
                commit("PERSONAL_GET_USER_DATA", response.data);
                commit("STOP_PRELOADER");
                resolve(response);
            })
            .catch(error => {
                commit("PERSONAL_GET_ERROR", error.response.data.message);
                commit("STOP_PRELOADER");
                reject(error);
            });
        });
    },
    PERSONAL_UPDATE_AVATAR({ commit }, file) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
        axios
            .post("/user/update/avatar", file)
            .then(response => {
                commit("PERSONAL_GET_USER_DATA", response.data);
                commit("STOP_PRELOADER");
                resolve(response);
            })
            .catch(error => {
                commit("STOP_PRELOADER");
                reject(error);
            });
        });
    },
    PERSONAL_DELETE_AVATAR({ commit }) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
        axios
            .delete("/user/delete/avatar")
            .then(response => {
                commit("PERSONAL_GET_USER_DATA", response.data);
                commit("STOP_PRELOADER");
                resolve(response);
            })
            .catch(error => {
                commit("STOP_PRELOADER");
                reject(error);
            });
        });
    },
    PERSONAL_GET_FIELDS({ commit }) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
        axios
            .get("/user/fields")
            .then(response => {
                commit("PERSONAL_GET_FIELDS", response.data);
                commit("STOP_PRELOADER");
                resolve(response);
            })
            .catch(error => {
                commit("STOP_PRELOADER");
                reject(error);
            });
        });
    },
    PERSONAL_SAVE_DATA({ commit }, data) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
        axios
            .post("/user/fields/store", data)
            .then(response => {
                commit("PERSONAL_GET_FIELDS", response.data);
                commit("STOP_PRELOADER");
                resolve(response);
            })
            .catch(error => {
                commit("STOP_PRELOADER");
                reject(error);
            });
        });
    },
    PERSONAL_SET_CERTIFICATE({ commit }, file) {
        return new Promise((resolve, reject) => {
            axios
                .post('/user/certificate/upload', file)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit("STOP_PRELOADER")
                })
        })
    },
    PERSONAL_SEND_EMAIL_CONFIRM({ commit }, query) {
        commit("LAUNCH_PRELOADER");

        return new Promise((resolve, reject) => {
            axios
              .post("/auth/email/verify", query)
              .then(response => {
                  commit("STOP_PRELOADER");
                  resolve(response);
              })
              .catch(error => {
                  commit("STOP_PRELOADER");
                  reject(error);
              });
        });
    },
};

export default {
    state,
    actions,
    mutations,
};
